<template>
	<Header
		v-model:show-archived="showArchived"
		:list-state="listState"
		search
		title="Apps"
		@create="appFormVisible = true" />
	<ProgressBar
		v-if="listState.isLoading.value"
		class="loader"
		mode="indeterminate"></ProgressBar>

	<Container
		v-if="listState.isLoaded.value && listState.list.value.length > 0"
		class="apps-list">
		<Card
			v-for="app in listState.list.value"
			:key="app.id"
			class="apps-list__card"
			@click="openDetails(app)">
			<template #header>
				<div class="apps-list__card-header">
					<span class="apps-list__card-title">{{ app.name }}</span>
				</div>
			</template>
			<template #content>
				<p class="apps-list__card-subtitle">
					{{ app.description }}
				</p>
			</template>
			<template #footer>
				<div class="apps-list__card-badge-container">
					<Badge
						v-if="app.archived"
						:class="['apps-list__card-badge', 'apps-list__card-badge--archived']">
						<i
							class="fa fa-box-archive apps-list__card-badge-icon"
							aria-hidden="true"></i>
						Archived
					</Badge>
					<Badge
						v-if="app.builds?.at(-1)?.deployments?.[0]?.active"
						:class="['apps-list__card-badge', 'apps-list__card-badge--emphasized']">
						<i
							class="fa fa-rocket apps-list__card-badge-icon"
							aria-hidden="true"></i>
						Deployed
					</Badge>
					<Badge
						v-if="app.builds?.at(-1)?.code_deliveries?.[0]?.status === 'success'"
						:class="['apps-list__card-badge']">
						<i
							class="fa fa-truck-fast apps-list__card-badge-icon"
							aria-hidden="true"></i>
						Delivered
					</Badge>
					<Badge
						v-if="isPurchased(app)"
						:class="['apps-list__card-badge']">
						<i
							class="fa fa-dollar apps-list__card-badge-icon"
							aria-hidden="true"></i>
						Purchased
					</Badge>
				</div>
			</template>
		</Card>
	</Container>
	<Container
		v-else-if="listState.isLoaded.value"
		class="welcome">
		<h1 class="welcome__heading">Welcome to CodeDjen!</h1>
		<h2 class="welcome__subheading">To get started, create your first app.</h2>
		<Button
			class="welcome__create-button"
			label="Create My First App"
			icon="fal fa-plus"
			@click="appFormVisible = true" />
	</Container>
	<Form
		as-dialog
		should-redirect
		:visible="appFormVisible"
		@close="appFormVisible = false" />
</template>

<script setup lang="ts">
import Header from './components/Header.vue'
import { ref, onBeforeMount, watch } from 'vue'
import { useRouter } from 'vue-router'
import Container from '@/components/Container.vue'
import Form from './components/Form.vue'
import { useAppListState } from '@/models/App/States'
import type { App, AppSize } from '@/models/App/Model'
import Card from 'primevue/card'
import Badge from 'primevue/badge'
import ProgressBar from 'primevue/progressbar'
import Button from 'primevue/button'

const listState = useAppListState()
const router = useRouter()
listState.defaultParams = {
	with: ['purchases', 'builds', 'builds.deployments', 'builds.codeDeliveries'],
}

onBeforeMount(() => {
	listState.query().where('archived', '=', false).save()
	listState.getList()
})

const appFormVisible = ref(false)

const appSize: Record<AppSize, number> = {
	small: 5,
	medium: 10,
	large: 15,
} as const

function isPurchased(app: App) {
	const purchaseExists = !!app.purchases?.length
	const currentAppsize = JSON.parse(app.builds?.at(-1)?.app_schema ?? '')?.length - 1 // ignore pre-generated user module
	const boughtAppSize = app.licence ? appSize[app.licence] : 0
	const licenceToSmall = currentAppsize > boughtAppSize
	return purchaseExists && !licenceToSmall
}

function openDetails(app: App) {
	router.push({ name: 'apps-details', params: { id: app.id } })
}

const showArchived = ref(false)
watch(showArchived, () => {
	if (showArchived.value) {
		listState.query().where('archived', '=', true).orWhere('archived', '=', false).save()
	} else {
		listState.query().where('archived', '=', false).save()
	}
	listState.getList()
})
</script>

<style lang="scss" scoped>
.loader {
	height: 2px;
	margin-bottom: -2px;
	width: 100%;
	border-radius: 0;
}

.apps-list {
	display: grid;
	grid-template-columns: 1fr;
	gap: 16px;

	@media (min-width: 768px) {
		grid-template-columns: 1fr 1fr;
	}
	@media (min-width: 1024px) {
		grid-template-columns: 1fr 1fr 1fr;
	}

	.apps-list__card {
		display: flex;
		flex-direction: column;

		&:hover {
			cursor: pointer;
			background: var(--p-surface-100);

			@media (prefers-color-scheme: dark) {
				background: var(--p-surface-800);
			}
		}

		&:deep(.p-card-body) {
			padding: 0;
			flex: 1;

			.p-card-content {
				flex: 1;
			}
		}

		&-header {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			padding: 16px;
		}

		&-title {
			font-size: 18px;
			font-weight: bold;
			flex: 1;
		}

		&-subtitle {
			font-size: 14px;
			color: var(--p-text-secondary);
			padding: 0 16px;
		}

		&-badge-container {
			display: flex;
			padding: 10px;
			justify-content: flex-end;
		}

		&-badge {
			display: inline-flex;
			align-items: center;
			border: 1px solid var(--p-primary-500);
			background: transparent;
			color: var(--p-primary-500);
			font-size: 12px;
			padding: 4px 8px;
			border-radius: 16px;
			margin-left: 8px;

			&--emphasized {
				border-color: var(--p-primary-500);
				background: var(--p-primary-500);
				color: white;
			}

			&--archived {
				border-color: var(--p-gray-400);
				color: var(--p-gray-400);
			}
		}

		&-badge-icon {
			margin-right: 5px;
		}
	}
}

.welcome {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 16px;
	padding: 16px;
	width: 100%;
	padding-top: 30px;

	.welcome__heading {
		font-size: 32px;
		font-weight: bold;
		margin: 0;
		line-height: 1;
	}

	.welcome__subheading {
		margin: 0;
		line-height: 1;
		font-size: 18px;
		color: var(--p-text-secondary);
	}

	.welcome__create-button {
		margin-top: 5px;
	}
}
</style>
