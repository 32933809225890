<template>
	<Card class="purchase-history">
		<template #title>
			<h4 class="purchase-history__title">Purchase History</h4>
		</template>
		<template #content>
			<ApiTable
				:list-state="listState"
				clickable
				flat
				@row-click="openApp">
				<Column header="Size">
					<template #body="slotProps">
						<span>{{
							slotProps.data.size.at(0).toUpperCase() + slotProps.data.size.slice(1)
						}}</span>
					</template>
				</Column>
				<Column header="Purchase Date">
					<template #body="slotProps">
						<span>{{ format(slotProps.data.created_at, 'LLL do yyyy') }}</span>
					</template>
				</Column>
				<Column header="App">
					<template #body="slotProps">
						<div class="purchase-history__app-container">
							<span class="purchase-history__app-name">{{ slotProps.data.app.name }}</span>
							<span class="purchase-history__app-description">{{
								slotProps.data.app.description
							}}</span>
						</div>
					</template>
				</Column>
				<Column header="Amount">
					<template #body="slotProps">
						<span>{{ slotProps.data.amount }}</span>
					</template>
				</Column>
			</ApiTable>
		</template>
	</Card>
</template>

<script setup lang="ts">
import { defineProps, onBeforeMount, ref } from 'vue'
import Card from 'primevue/card'
import ApiTable from '@/components/Table/ApiTable.vue'
import Column from 'primevue/column'
import type { User } from '@/models/User/Model'
import QueryBuilder from '@/helpers/models/QueryBuilder'
import { usePurchaseListState } from '@/models/Purchase/States'
import type { Purchase, PurchaseModel } from '@/models/Purchase/Model'
import { format } from 'date-fns'
import { useRouter } from 'vue-router'

const emit = defineEmits(['start-loading', 'stop-loading'])

const router = useRouter()
const props = defineProps<{
	userId: User['id']
}>()

const listLoading = ref(false)
const listState = usePurchaseListState()
listState.defaultParams = {
	per_page: 10,
	fromRelation: {
		model: 'App\\Models\\User',
		id: props.userId,
		relation: 'purchases',
	},
	filters: [new QueryBuilder<PurchaseModel>().where('status', '=', 'processed').getFilter()],
	with: ['app'],
}

onBeforeMount(() => {
	refresh()
})

async function refresh() {
	listLoading.value = true
	emit('start-loading')
	await listState.getList()
	listLoading.value = false
	emit('stop-loading')
}

function openApp(row: { data: Purchase }) {
	router.push(`/apps/${row.data.app.id}`)
}
</script>

<style scoped lang="scss">
.purchase-history {
	overflow: hidden;
	width: 100%;

	:deep(.p-card-body) {
		padding: 20px 0 0;

		.p-card-caption {
			padding: 0px 20px 12px;

			.p-card-title {
				display: flex;
				align-items: center;
				gap: 10px;

				.users-relation-widget__title {
					flex: 1;
					text-align: left;
				}
			}
		}
	}
	.purchase-history__app-container {
		display: flex;
		flex-direction: column;

		.purchase-history__app-name,
		.purchase-history__app-description {
			display: block;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.purchase-history__app-description {
			font-size: 12px;
			font-weight: normal;
		}
	}
}
</style>
