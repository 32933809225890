import { createRouter, createWebHistory } from 'vue-router'
import authGuard from './guards/authGuard'
import { useAuthStore } from '@/stores/Auth'

import Login from '../views/Auth/Login.vue'
import Register from '../views/Auth/Register.vue'
import VerifyEmail from '../views/Auth/VerifyEmail.vue'
import AppList from '../views/App/List.vue'
import AppDetails from '../views/App/Details.vue'
import AppCheckout from '../views/App/Checkout.vue'
import AppCompleteCheckout from '../views/App/CompleteCheckout.vue'
import BuildList from '../views/Build/List.vue'
import BuildEdit from '../views/Build/Edit.vue'
import DeploymentList from '../views/Deployment/List.vue'
import DeploymentEdit from '../views/Deployment/Edit.vue'
import PurchaseList from '../views/Purchase/List.vue'
import PurchaseEdit from '../views/Purchase/Edit.vue'
import RepositoryConfigList from '../views/RepositoryConfig/List.vue'
import RepositoryConfigEdit from '../views/RepositoryConfig/Edit.vue'
import CodeDeliveryList from '../views/CodeDelivery/List.vue'
import CodeDeliveryEdit from '../views/CodeDelivery/Edit.vue'
import ForgotPassword from '../views/Auth/ForgotPassword.vue'
import ResetPassword from '../views/Auth/ResetPassword.vue'
import UserDetails from '../views/User/Details.vue'
import AuthApi from '@/helpers/api/AuthApi'
import NotFound from '../views/NotFound.vue'
import adminGuard from './guards/adminGuard'
/* GENERATOR(IMPORT) */

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes: [
		{
			path: '/',
			redirect: '/apps',
		},
		{
			path: '/verify-email',
			name: 'verify-email-notice',
			component: VerifyEmail,
		},
		{
			path: '/email/verify/:id/:hash',
			name: 'email-verification',
			component: VerifyEmail,
			beforeEnter: async (to) => {
				await new AuthApi().verifyEmail({
					id: to.params.id as string,
					hash: to.params.hash as string,
					expires: to.query.expires as string,
					signature: to.query.signature as string,
				})
				return { path: '/verify-email', query: {} }
			},
		},
		{
			path: '/forgot-password',
			name: 'forgot-password',
			component: ForgotPassword,
		},
		{
			path: '/reset-password',
			name: 'reset-password',
			component: ResetPassword,
			beforeEnter: async () => {
				const auth = useAuthStore()
				await auth.logout()
			},
		},
		{
			path: '/login',
			name: 'login',
			component: Login,
		},
		{
			path: '/register',
			name: 'register',
			component: Register,
		},
		{
			path: '/logout',
			component: Login,
			beforeEnter: async () => {
				const auth = useAuthStore()
				await auth.logout()
			},
		},
		{
			path: '/apps',
			children: [
				{
					path: '',
					name: 'apps-list',
					component: AppList,
				},
				{
					path: '/apps/create',
					name: 'apps-create',
					component: AppList,
				},
				{
					path: '/apps/:id/checkout',
					name: 'apps-checkout',
					component: AppCheckout,
				},
				{
					path: '/apps/:id/complete-checkout',
					name: 'apps-complete-checkout',
					component: AppCompleteCheckout,
				},
				{
					path: '/apps/:id',
					name: 'apps-details',
					component: AppDetails,
				},
				{
					path: '/apps/:id/repo-config',
					name: 'apps-repo-config',
					component: AppDetails,
				},
			],
		},
		{
			path: '/builds',
			beforeEnter: [adminGuard],
			children: [
				{
					path: '',
					name: 'builds-list',
					component: BuildList,
				},
				{
					path: '/builds/create',
					name: 'builds-create',
					component: BuildEdit,
				},
				{
					path: '/builds/:id',
					name: 'builds-edit',
					component: BuildEdit,
				},
			],
		},
		{
			path: '/deployments',
			beforeEnter: [adminGuard],
			children: [
				{
					path: '',
					name: 'deployments-list',
					component: DeploymentList,
				},
				{
					path: '/deployments/create',
					name: 'deployments-create',
					component: DeploymentEdit,
				},
				{
					path: '/deployments/:id',
					name: 'deployments-edit',
					component: DeploymentEdit,
				},
			],
		},
		{
			path: '/purchases',
			beforeEnter: [adminGuard],
			children: [
				{
					path: '',
					name: 'purchases-list',
					component: PurchaseList,
				},
				{
					path: '/purchases/create',
					name: 'purchases-create',
					component: PurchaseEdit,
				},
				{
					path: '/purchases/:id',
					name: 'purchases-edit',
					component: PurchaseEdit,
				},
			],
		},
		{
			path: '/repository-configs',
			beforeEnter: [adminGuard],
			children: [
				{
					path: '',
					name: 'repository-configs-list',
					component: RepositoryConfigList,
				},
				{
					path: '/repository-configs/create',
					name: 'repository-configs-create',
					component: RepositoryConfigEdit,
				},
				{
					path: '/repository-configs/:id',
					name: 'repository-configs-edit',
					component: RepositoryConfigEdit,
				},
			],
		},
		{
			path: '/code-deliveries',
			beforeEnter: [adminGuard],
			children: [
				{
					path: '',
					name: 'code-deliveries-list',
					component: CodeDeliveryList,
				},
				{
					path: '/code-deliveries/create',
					name: 'code-deliveries-create',
					component: CodeDeliveryEdit,
				},
				{
					path: '/code-deliveries/:id',
					name: 'code-deliveries-edit',
					component: CodeDeliveryEdit,
				},
			],
		},
		{
			path: '/account',
			children: [
				{
					path: '',
					name: 'account-details',
					component: UserDetails,
				},
			],
		},
		{
			path: '/404-not-found',
			name: 'not-found',
			component: NotFound,
		},
		{
			path: '/:pathMatch(.*)*',
			redirect: '/login',
		},
	],
})

router.beforeEach(authGuard)

export default router
