<template>
	<div class="forgot-password">
		<Card class="forgot-password__card">
			<template #title>Forgot Password</template>
			<template #content>
				<form
					v-if="!emailSent"
					@submit.prevent>
					<p>Enter your email and we'll send you a password reset link</p>
					<div class="forgot-password__input-container">
						<label for="email">Email</label>
						<InputText
							id="email"
							v-model="form.email"
							class="forgot-password__input"
							:class="{ 'forgot-password__input--error': formErrors?.email }"
							@keyup.enter="requestPasswordReset()" />
						<small
							v-if="formErrors?.email"
							id="email"
							class="forgot-password__input-error-message"
							>{{ formErrors.email?.[0] }}</small
						>
					</div>
				</form>
				<p v-else>
					An email has been sent to your email address with a password reset link. Please check your
					email.
				</p>
				<Button
					v-if="!emailSent"
					:loading
					@click="requestPasswordReset()">
					Request password reset
				</Button>
			</template>
		</Card>
	</div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import Button from 'primevue/button'
import Card from 'primevue/card'
import { useAuthStore } from '@/stores/Auth'
import type { RequestPasswordResetParams } from '@/helpers/api/AuthApi'
import InputText from 'primevue/inputtext'

const auth = useAuthStore()
const loading = ref(false)
const form = ref<RequestPasswordResetParams>({
	email: '',
})
const formErrors = ref<{ email?: string } | null>(null)
const emailSent = ref(false)

async function requestPasswordReset() {
	formErrors.value = null
	loading.value = true
	try {
		const res = await auth.requestPasswordReset(form.value)
		console.log(res)
		emailSent.value = true
		console.log(res)
	} catch (error: any) {
		if (error?.response?.status === 422 && error.response.data.errors) {
			formErrors.value = error.response.data.errors
		} else {
			throw error
		}
	} finally {
		loading.value = false
	}
}
</script>

<style scoped lang="scss">
.forgot-password {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	width: 100%;

	.forgot-password__card {
		width: 500px;

		&:deep(.p-card-content) {
			display: flex;
			flex-direction: column;
			p {
				margin-bottom: 16px;
			}
		}

		form {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 8px;

			& > * {
				width: 100%;
			}

			.forgot-password__input-container {
				display: flex;
				flex-direction: column;
				gap: 5px;
				margin-bottom: 10px;

				.forgot-password__input-error-message {
					color: var(--p-red-500);
				}

				.forgot-password__input--error {
					border-color: var(--p-red-500);
				}
			}
		}
	}
}
</style>
