<template>
	<div class="header-toolbar w-full">
		<Button
			as="router-link"
			to="/builds"
			icon="fas fa-chevron-left"
			severity="secondary" />
		<h2>{{ props.title }}</h2>
	</div>
</template>

<script setup lang="ts">
import { defineProps } from 'vue'
import Button from 'primevue/button'

const props = defineProps({
	title: {
		type: String,
		required: true,
	},
})
</script>

<style scoped lang="scss">
.header-toolbar {
	background: var(--p-surface-100);
	position: sticky;
	top: 0;
	z-index: 1000;
	border-left: 0;
	border-top: 0;
	border-right: 0;
	border-bottom: 1px solid var(--p-surface-200);
	display: flex;
	align-items: center;
	gap: 16px;
	padding: 10px;

	@media (prefers-color-scheme: dark) {
		background: var(--p-surface-950);
		border-bottom-color: var(--p-surface-900);
	}
}
</style>
