<template>
	<FormContainer
		class="form"
		:visible
		:title="props.isEdit ? 'Update Deployment' : 'Create Deployment'"
		:as-dialog
		@close="emit('close')">
		<form>
			<div class="form__input-container">
				<label :for="`${uuid}-build_id`">Build Id</label>
				<ModelSelect
					v-if="!props.hideInputs.includes('build_id')"
					:id="`${uuid}-build_id`"
					v-model="form.build_id"
					:api="new BuildsApi()"
					:disabled="!!props.forceValues.build_id"
					option-label="query" />
			</div>
			<Button
				:loading="loading"
				icon="fal fa-save"
				:label="isEdit ? 'Update' : 'Create'"
				@click="submit"
				@submit.stop />
		</form>
	</FormContainer>
</template>

<script setup lang="ts">
import { ref, watch, onBeforeMount, toRaw, getCurrentInstance } from 'vue'
import { useRouter } from 'vue-router'
import Button from 'primevue/button'
import ModelSelect from '@/components/ModelSelect.vue'
import BuildsApi from '@/models/Build/Api'
import type { Deployment } from '@/models/Deployment/Model'
import DeploymentsApi from '@/models/Deployment/Api'
import FormContainer from '@/components/FormContainer.vue'

const emit = defineEmits(['start-loading', 'stop-loading', 'close', 'created', 'updated'])
const props = withDefaults(
	defineProps<{
		isEdit?: boolean
		id?: Deployment['id']
		hideInputs?: (keyof (typeof form)['value'])[]
		defaultValues?: Partial<(typeof form)['value']>
		forceValues?: Partial<(typeof form)['value']>
		shouldRedirect?: boolean
		attachTo?: Record<string, { method: 'associate' | 'syncWithoutDetaching'; id: string | number }>
		asDialog?: boolean
		visible?: boolean
	}>(),
	{
		id: undefined,
		hideInputs: () => [],
		defaultValues: () => ({}),
		forceValues: () => ({}),
		shouldRedirect: true,
		attachTo: undefined,
		asDialog: false,
		visible: false,
	},
)

const instance = getCurrentInstance()
const uuid = ref(instance!.uid)
const router = useRouter()
const form = ref(getDefaultForm())
const loading = ref(false)

watch(loading, (val: boolean) => {
	if (val) emit('start-loading')
	else emit('stop-loading')
})

onBeforeMount(reset)

async function reset() {
	if (props.defaultValues) {
		populateForm(props.defaultValues)
	}
	if (props.isEdit) {
		loading.value = true
		populateForm((await getDetails()) as any)
	}
}

function getDefaultForm() {
	return {
		build_id: 0,
	}
}

async function getDetails() {
	loading.value = true
	let res = await new DeploymentsApi().show(props.id!)
	loading.value = false
	return res.data
}

function populateForm(entity: Partial<(typeof form)['value']>) {
	Object.assign(form.value, { ...getDefaultForm(), ...entity })
}

async function create() {
	loading.value = true
	try {
		let params = structuredClone(toRaw(form.value))
		Object.assign(params, props.forceValues ?? {})
		let res = await new DeploymentsApi().store(params)
		await attachToAll(res.data.id)
		emit('created')
		return res.data
	} finally {
		loading.value = false
	}
}

async function update() {
	loading.value = true
	try {
		let params = structuredClone(toRaw(form.value))
		Object.assign(params, props.forceValues ?? {})
		let res = await new DeploymentsApi().update(props.id!, params)
		await attachToAll(res.data.id)
		emit('updated')
		return res.data
	} finally {
		loading.value = false
	}
}

async function attachToAll(id: string | number) {
	if (!props.attachTo) return
	for (let [key, value] of Object.entries(props.attachTo)) {
		loading.value = true
		try {
			await new DeploymentsApi().updateRelation(id, key, {
				method: value.method,
				params: [value.id],
			})
		} finally {
			loading.value = false
		}
	}
}

async function submit() {
	if (props.isEdit && props.id) {
		update()
	} else {
		let entity = await create()
		if (props.shouldRedirect) {
			router.push({ name: 'deployments-edit', params: { id: entity!.id } })
		}
	}
	emit('close')
}
</script>

<style lang="scss">
.form {
	form {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 10px;

		& > * {
			width: 100%;
		}

		.form__footer-container {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			gap: 10px;
		}

		&--edit {
			.form__footer-container {
				justify-content: space-between;
			}
		}
	}
}
</style>
