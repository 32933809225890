import AuthApi, {
	type RequestPasswordResetParams,
	type ResetPasswordParams,
} from '@/helpers/api/AuthApi'
import type { User } from '@/models/User/Model'
import { defineStore } from 'pinia'

const auth = new AuthApi()

const useAuthStore = defineStore('auth', {
	state: () => {
		return {
			user: null as User | null,
			userReturned: false,
			lastRouteCached: sessionStorage.getItem('lastRoute') || '/',
		}
	},
	actions: {
		async login({ email, password }: { email: string; password: string }) {
			this.setLastRoute(null)
			await auth.login({ email, password })
			await this.getUser()
		},
		async logout() {
			await auth.logout()
			this.user = null
		},
		async getUser() {
			try {
				const res = await auth.getUser()
				this.user = res.data
			} catch (error) {
				//
			} finally {
				this.userReturned = true
			}
		},
		async register(params: { email: string; password: string; password_confirmation: string }) {
			this.setLastRoute(null)
			await auth.register(params)
		},
		setLastRoute(route: string | null) {
			if (!route) route = '/'
			sessionStorage.setItem('lastRoute', route)
			this.lastRouteCached = route
		},
		githubLogin() {
			this.setLastRoute(null)
			auth.githubLoginRedirect()
		},
		googleLogin() {
			this.setLastRoute(null)
			auth.googleLoginRedirect()
		},
		async requestPasswordReset(params: RequestPasswordResetParams) {
			return await auth.requestPasswordReset(params)
		},
		async resetPassword(params: ResetPasswordParams) {
			return await auth.resetPassword(params)
		},
	},
	getters: {
		lastRoute(state) {
			return state.lastRouteCached
		},
	},
})

export { useAuthStore }
