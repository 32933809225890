<template>
	<div class="header-toolbar w-full">
		<Button
			v-if="!isList"
			class="header__back-button"
			as="router-link"
			to="/apps"
			icon="fal fa-chevron-left"
			severity="secondary" />
		<Inplace :style="!props.detailsState?.details.value ? 'pointer-events: none;' : ''">
			<template #display>
				<h2 v-if="props.detailsState">{{ props.detailsState.details.value?.name }}</h2>
				<h2 v-else>Apps</h2>
			</template>
			<template #content="{ closeCallback }">
				<span>
					<InputText v-model="name" />
					<Button
						class="header__inplace-save"
						icon="fal fa-floppy-disk"
						severity="secondary"
						@click="updateName(closeCallback)" />
				</span>
			</template>
		</Inplace>
		<Inplace
			v-if="props.detailsState?.details.value"
			class="header__description-inplace">
			<template #display>
				<h3 class="header__description">{{ props.detailsState.details.value?.description }}</h3>
			</template>
			<template #content="{ closeCallback }">
				<span>
					<InputText v-model="description" />
					<Button
						class="header__inplace-save"
						icon="fal fa-floppy-disk"
						severity="secondary"
						@click="updateDescription(closeCallback)" />
				</span>
			</template>
		</Inplace>
		<InputText
			v-if="props.search && listState"
			v-model="searchString"
			class="header-toolbar__search"
			@update:model-value="listState.getList({ search: searchString })"
			@keyup.enter="listState.getList({ search: searchString })"></InputText>
		<ToggleButton
			v-if="isList"
			v-model="showArchived"
			on-label="Show Archived"
			off-label="Hide Archived"
			on-icon="fa fal fa-box-archive"
			off-icon="fa fal fa-box-archive" />
		<Button
			v-if="isList"
			icon="fal fa-plus"
			label="Create App"
			@click="emit('create')" />
		<Button
			v-if="!isList"
			icon="fab fa-github"
			severity="secondary"
			label="Repository Config"
			variant="outlined"
			@click="emit('repository-config-click')" />
		<Button
			v-if="!isList && !props.detailsState?.details.value?.archived"
			icon="fal fa-box-archive"
			severity="danger"
			variant="outlined"
			label="Archive App"
			@click.stop="archive()" />
		<Button
			v-if="!isList && props.detailsState?.details.value?.archived"
			icon="fal fa-box-archive"
			severity="secondary"
			variant="outlined"
			label="Restore App"
			@click.stop="restore()" />
	</div>
</template>

<script setup lang="ts">
import { computed, ref, watch, defineModel } from 'vue'
import { useRoute } from 'vue-router'
import ListState from '@/helpers/models/ListState'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import Inplace from 'primevue/inplace'
import { AppDetailsState } from '@/models/App/States'
import AppsApi from '@/models/App/Api'
import { useConfirm } from 'primevue/useconfirm'
import ToggleButton from 'primevue/togglebutton'

const showArchived = defineModel<boolean>('showArchived')

const emit = defineEmits(['create', 'repository-config-click', 'archived-change'])

const props = withDefaults(
	defineProps<{
		search?: boolean
		listState?: ListState<any, any, any>
		detailsState?: AppDetailsState
	}>(),
	{
		search: false,
		listState: undefined,
		detailsState: undefined,
	},
)

const confirm = useConfirm()
const route = useRoute()
const isList = computed(() => route.name === 'apps-list' || route.name === 'apps-create')
const name = ref(props.detailsState?.details.value?.name ?? '')
const description = ref(props.detailsState?.details.value?.description ?? '')
const searchString = ref('')

if (props.detailsState) {
	watch(props.detailsState?.details, () => {
		name.value = props.detailsState?.details.value?.name ?? ''
		description.value = props.detailsState?.details.value?.description ?? ''
	})
}

function updateName(closeCallback: () => void) {
	closeCallback()
	if (!props.detailsState) return
	props.detailsState.update({ name: name.value })
}

function updateDescription(closeCallback: () => void) {
	closeCallback()
	if (!props.detailsState) return
	props.detailsState.update({ description: description.value })
}

async function archive() {
	if (!props.detailsState || !props.detailsState.details.value?.id) return
	confirm.require({
		message: `Are you sure you want to archive the app? You'll be able to restore it later.`,
		header: 'Are you sure?',
		icon: 'fa fa-exclamation-triangle',
		rejectLabel: 'Cancel',
		rejectProps: {
			label: 'Cancel',
			severity: 'secondary',
			outlined: true,
		},
		acceptProps: {
			label: 'Archive',
			severity: 'danger',
		},
		accept: async () => {
			await new AppsApi().destroy(Number(props.detailsState!.details.value?.id))
			await props.detailsState!.getDetails(props.detailsState!.details.value!.id)
			emit('archived-change')
		},
		reject: () => {},
	})
}

async function restore() {
	if (!props.detailsState || !props.detailsState.details.value?.id) return
	confirm.require({
		message: `Are you sure you want to restore the app?`,
		header: 'Are you sure?',
		icon: 'fa fa-exclamation-triangle',
		rejectLabel: 'Cancel',
		rejectProps: {
			label: 'Cancel',
			severity: 'secondary',
			outlined: true,
		},
		acceptProps: {
			label: 'Restore',
			severity: 'success',
		},
		accept: async () => {
			await new AppsApi().update(Number(props.detailsState!.details.value!.id), { archived: false })
			await props.detailsState!.getDetails(props.detailsState!.details.value!.id)
			emit('archived-change')
		},
		reject: () => {},
	})
}
</script>

<style scoped lang="scss">
.header-toolbar {
	background: var(--p-surface-0);
	position: sticky;
	top: 0;
	z-index: 1000;
	border-left: 0;
	border-top: 0;
	border-right: 0;
	border-bottom: 1px solid var(--p-surface-200);
	display: flex;
	align-items: center;
	gap: 16px;
	padding: 10px;
	height: 64px;

	@media (prefers-color-scheme: dark) {
		background: var(--p-surface-950);
		border-bottom-color: var(--p-surface-900);
	}

	.header-toolbar__search {
		flex: 1;
	}

	.header__description-inplace {
		flex: 1;
		overflow: hidden;
		display: flex;
		align-items: center;

		:deep(.p-inplace-display) {
			overflow: hidden;
			width: 100%;
		}

		.header__description {
			text-overflow: ellipsis;
			text-wrap: nowrap;
			overflow: hidden;
		}
	}

	.header__inplace-save {
		margin-left: 10px;
	}

	.header__back-button {
		min-width: 40px;
	}

	h2 {
		font-size: 22px;
	}
}
</style>
