<template>
	<div class="reset-password">
		<Card class="reset-password__card">
			<template #title>Reset Password</template>
			<template #content>
				<p>Enter new password to reset it</p>
				<!--				<div class="reset-password__input-container">
					<label for="email">Email</label>
					<InputText
						id="email"
						v-model="form.email"
						class="reset-password__input"
						:class="{ 'reset-password__input--error': formErrors?.email }"
						@keyup.enter="requestPasswordReset()" />
					<small
						v-if="formErrors?.email"
						id="email"
						class="reset-password__input-error-message"
						>{{ formErrors.email }}</small
					>
</div>-->
				<form @submit.prevent>
					<div class="reset-password__input-container">
						<label for="password">Password</label>
						<InputText
							id="password"
							v-model="form.password"
							class="reset-password__input"
							:class="{ 'reset-password__input--error': formErrors?.password }"
							type="password"
							@keyup.enter="resetPassword()" />
						<small
							v-if="formErrors?.password"
							id="password"
							class="reset-password__input-error-message"
							>{{ formErrors.password }}</small
						>
					</div>
					<div class="reset-password__input-container">
						<label for="password-confirmation">Password Confirmation</label>
						<InputText
							id="password-confirmation"
							v-model="form.password_confirmation"
							class="reset-password__input"
							:class="{ 'reset-password__input--error': formErrors?.password_confirmation }"
							type="password"
							@keyup.enter="resetPassword()" />
						<small
							v-if="formErrors?.password_confirmation"
							id="password-confirmation"
							class="reset-password__input-error-message"
							>{{ formErrors.password_confirmation }}</small
						>
					</div>
				</form>
				<Button
					:loading
					@click="resetPassword()">
					Request password reset
				</Button>
			</template>
		</Card>
	</div>
</template>

<script setup lang="ts">
import { ref, onBeforeMount } from 'vue'
import Button from 'primevue/button'
import Card from 'primevue/card'
import { useAuthStore } from '@/stores/Auth'
import type { ResetPasswordParams } from '@/helpers/api/AuthApi'
import { useRoute, useRouter } from 'vue-router'
import InputText from 'primevue/inputtext'
import { useToast } from 'primevue/usetoast'

const route = useRoute()
const router = useRouter()
const auth = useAuthStore()
const loading = ref(false)
const toast = useToast()
const form = ref<ResetPasswordParams>({
	token: '',
	email: '',
	password: '',
	password_confirmation: '',
})
const formErrors = ref<{
	email?: string
	token?: string
	password?: string
	password_confirmation?: string
} | null>(null)

async function resetPassword() {
	formErrors.value = null
	loading.value = true
	try {
		await auth.resetPassword(form.value)
		toast.add({
			severity: 'success',
			summary: 'Password reset',
			detail: 'Password reset successfully',
			life: 3000,
		})
		router.push({ name: 'login' })
	} catch (error: any) {
		if (error?.response?.status === 422 && error.response.data.errors) {
			formErrors.value = error.response.data.errors
		} else {
			throw error
		}
	} finally {
		loading.value = false
	}
}

onBeforeMount(() => {
	form.value.token = route.query.token as string
	form.value.email = route.query.email as string
})
</script>

<style scoped lang="scss">
.reset-password {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	width: 100%;

	.reset-password__card {
		width: 500px;

		&:deep(.p-card-content) {
			display: flex;
			flex-direction: column;
			p {
				margin-bottom: 16px;
			}
		}

		form {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 8px;

			& > * {
				width: 100%;
			}

			.reset-password__input-container {
				display: flex;
				flex-direction: column;
				gap: 5px;
				margin-bottom: 10px;

				.reset-password__input-error-message {
					color: var(--p-red-500);
				}

				.reset-password__input--error {
					border-color: var(--p-red-500);
				}
			}
		}
	}
}
</style>
