<template>
	<Header title="Account Details" />
	<ProgressBar
		v-if="loaders.size > 0"
		mode="indeterminate"
		style="height: 6px" />
	<Container class="details">
		<Form
			:id="authStore.user!.id"
			is-edit
			@start-loading="loaders.add('form')"
			@stop-loading="loaders.delete('form')" />
		<PurchaseHistory
			class="details__purchase-history"
			:user-id="authStore.user!.id" />
	</Container>
</template>

<script setup lang="ts">
import { reactive } from 'vue'
import Header from './components/Header.vue'
import Form from './components/Form.vue'
import ProgressBar from 'primevue/progressbar'
import Container from '@/components/Container.vue'
import { useAuthStore } from '@/stores/Auth'
import PurchaseHistory from './components/PurchaseHistory.vue'

const authStore = useAuthStore()
const loaders = reactive(new Set<string>())
</script>

<style lang="scss" scoped>
.details {
	display: grid;
	grid-template-columns: 1fr;
	gap: 11px;
	justify-items: center;
	align-items: start;
}
</style>
