<template>
	<div
		class="navigation"
		:class="{ 'navigation--expanded': locked }"
		@mouseover="isSidebarOpen = true"
		@mouseleave="isSidebarOpen = false">
		<div class="navigation__header">
			<Logo
				class="navigation__logo"
				:expanded="isSidebarOpen || locked" />
			<Button
				v-tooltip.bottom="locked ? 'Unlock sidebar' : 'Lock sidebar'"
				severity="secondary"
				:icon="lockIcon"
				text
				@click="toggleLocked" />
		</div>
		<div class="navigation__links-container">
			<router-link
				class="navigation__link-item"
				to="/apps">
				<i class="navigation__link-icon fat fa-mobile-alt"></i>
				<div class="navigation__link-title">Apps</div>
			</router-link>
			<template v-if="auth.user?.id === 1">
				<router-link
					class="navigation__link-item"
					to="/builds">
					<i class="navigation__link-icon fat fa-hammer"></i>
					<div class="navigation__link-title">Build</div>
				</router-link>
				<router-link
					class="navigation__link-item"
					to="/code-deliveries">
					<i class="navigation__link-icon fat fa-shipping-fast"></i>
					<div class="navigation__link-title">Code Deliveries</div>
				</router-link>
				<router-link
					class="navigation__link-item"
					to="/deployments">
					<i class="navigation__link-icon fat fa-rocket"></i>
					<div class="navigation__link-title">Deployments</div>
				</router-link>
				<router-link
					class="navigation__link-item"
					to="/purchases">
					<i class="navigation__link-icon fat fa-shopping-cart"></i>
					<div class="navigation__link-title">Purchases</div>
				</router-link>
				<router-link
					class="navigation__link-item"
					to="/repository-configs">
					<i class="navigation__link-icon fab fa-github"></i>
					<div class="navigation__link-title">Repository Configs</div>
				</router-link>
			</template>
		</div>
		<router-link
			class="navigation__footer"
			:class="{ 'navigation__footer--active': currentRoutePath === '/account' }"
			to="/account">
			<Avatar
				:label="initials"
				class="navigation__avatar" />
			<span class="navigation__email">{{ auth.user!.email }}</span>
			<Button
				class="navigation__logout-button"
				as="router-link"
				to="/logout"
				severity="secondary"
				icon="fat fa-arrow-right-from-bracket"
				text
				@mouseover.stop
				@click.stop="toggleLocked" />
		</router-link>
	</div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { useAuthStore } from '@/stores/Auth'
import Button from 'primevue/button'
import Avatar from 'primevue/avatar'
import Logo from '@/components/Logo.vue'
import { useRouter } from 'vue-router'

const auth = useAuthStore()
const router = useRouter()
const locked = ref(localStorage.getItem('sidebarLocked') === 'true')
const isSidebarOpen = ref(false)

const initials = computed(
	() => auth.user!.email[0].toUpperCase() + auth.user!.email[1].toUpperCase(),
)
const lockIcon = computed(() => {
	if (locked.value) {
		return 'fal fa-lock'
	} else {
		return 'fal fa-lock-open'
	}
})
const currentRoutePath = computed(() => router.currentRoute.value.path)

function toggleLocked() {
	locked.value = !locked.value
	localStorage.setItem('sidebarLocked', locked.value.toString())
}

function goToAccount() {
	router.push('/account')
}
</script>

<style scoped lang="scss">
.navigation {
	border-right: 1px solid var(--p-surface-200);
	display: flex;
	flex-direction: column;
	overflow: hidden;
	width: 52px;
	transition: width 0.3s;
	background-color: var(--p-surface-0);

	@media (prefers-color-scheme: dark) {
		border-right-color: var(--p-surface-900);
		background-color: var(--p-surface-950);
	}

	&.navigation--expanded,
	&:hover {
		width: 255px;

		.navigation__header {
			.navigation__logo {
				height: 32px;
			}
		}
	}

	.navigation__header {
		display: flex;
		align-items: center;
		padding: 10px;
		gap: 10px;
		border-bottom: 1px solid var(--p-surface-200);
		width: 255px;
		height: 64px;
		text-overflow: ellipsis;
		justify-content: space-between;

		@media (prefers-color-scheme: dark) {
			border-bottom-color: var(--p-surface-950);
		}

		.navigation__logo {
			height: 20px;
			transition: height 0.3s;
			flex-basis: content;
		}
	}

	.navigation__footer {
		display: flex;
		align-items: center;
		padding: 10px;
		gap: 10px;
		border-top: 1px solid var(--p-surface-200);
		width: 255px;
		height: 64px;
		text-overflow: ellipsis;

		&:has(:not(.navigation__logout-button:hover)):hover,
		&--active {
			background-color: var(--p-surface-100);
			cursor: pointer;

			@media (prefers-color-scheme: dark) {
				background-color: var(--p-surface-900);
			}
		}

		@media (prefers-color-scheme: dark) {
			border-top-color: var(--p-surface-950);
		}

		.navigation__email {
			flex: 1;
			text-overflow: ellipsis;
			overflow: hidden;
		}

		.navigation__avatar {
			min-width: 30px;
		}
	}

	.navigation__links-container {
		width: 255px;
		flex: 1;

		.navigation__link-item {
			display: flex;
			align-items: center;
			padding: 10px;
			gap: 10px;
			color: var(--p-text-color);
			text-decoration: none;

			&:hover {
				background-color: var(--p-surface-100);

				@media (prefers-color-scheme: dark) {
					background-color: var(--p-surface-900);
				}
			}

			&.router-link-active {
				background-color: var(--p-surface-100);

				@media (prefers-color-scheme: dark) {
					background-color: var(--p-surface-900);
				}
			}

			.navigation__link-title {
				flex: 1;
			}

			.navigation__link-icon {
				font-size: 22px;
				color: var(--p-surface-500);
				width: 32px;
				display: flex;
				justify-content: center;

				@media (prefers-color-scheme: dark) {
					color: var(--p-surface-400);
				}
			}
		}
	}

	.navigation__logo-container {
		padding: 8px;
	}
}
</style>
