<template>
	<FormContainer
		class="form"
		title="User Info"
		:visible
		:as-dialog
		@close="emit('close')">
		<form @submit.prevent>
			<FormInput
				label="Email"
				:error-message="formErrors.email">
				<InputText
					v-model="formData.email"
					readonly />
			</FormInput>
			<div class="form__footer-container">
				<Button
					class="form__change-password-button"
					icon="fal fa-key"
					:loading="loading"
					label="Change Password"
					severity="secondary"
					@click="changePassword"
					@submit.stop />
				<Button
					as="router-link"
					to="/logout"
					class="form__logout-button"
					icon="fal fa-arrow-right-from-bracket"
					:loading="loading"
					label="Logout"
					severity="secondary"
					@submit.stop />
			</div>
		</form>
	</FormContainer>
</template>

<script setup lang="ts">
import { watch } from 'vue'
import { useRouter } from 'vue-router'
import UsersApi from '@/models/User/Api'
import type { User } from '@/models/User/Model'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import { useForm } from '@/helpers/form'
import FormInput from '@/components/FormInput.vue'
import FormContainer from '@/components/FormContainer.vue'
import { useAuthStore } from '@/stores/Auth'
import { useToast } from 'primevue/usetoast'

type FormData = {
	email: string
}

const emit = defineEmits([
	'start-loading',
	'stop-loading',
	'close',
	'created',
	'updated',
	'deleted',
])

const props = withDefaults(
	defineProps<{
		isEdit?: boolean
		id?: User['id']
		hideInputs?: (keyof FormData)[]
		defaultValues?: Partial<FormData>
		forceValues?: Partial<FormData>
		shouldRedirect?: boolean
		attachTo?: Record<string, { method: 'associate' | 'syncWithoutDetaching'; id: string | number }>
		asDialog?: boolean
		visible?: boolean
		hideRemove?: boolean
	}>(),
	{
		id: undefined,
		hideInputs: () => [],
		defaultValues: () => ({}),
		forceValues: () => ({}),
		shouldRedirect: true,
		attachTo: undefined,
		asDialog: false,
		visible: false,
		hideRemove: false,
	},
)

const router = useRouter()
const authStore = useAuthStore()
const toast = useToast()

const { formData, loading, formErrors, reset } = useForm({
	api: new UsersApi(),
	defaultData: () => ({
		email: '',
	}),
	forceValues: props.forceValues,
	attachTo: props.attachTo,
	isEdit: props.isEdit,
	id: props.id,
	onStartLoading: () => emit('start-loading'),
	onStopLoading: () => emit('stop-loading'),
	onClose: () => emit('close'),
	onCreated: (entity) => {
		if (props.shouldRedirect) {
			router.push({ name: 'repository-configs-edit', params: { id: entity!.id } })
		}
		emit('created')
	},
	onUpdated: () => emit('updated'),
	onDeleted: () => emit('deleted'),
})

watch(
	() => props.visible,
	(val) => {
		if (val) reset()
	},
)

async function changePassword() {
	loading.value = true
	try {
		await authStore.requestPasswordReset({ email: authStore.user!.email })
		toast.add({
			severity: 'info',
			summary: 'Password reset requested',
			detail: `And email with password reset instructions has been sent to ${authStore.user!.email}`,
			life: 5000,
		})
	} finally {
		loading.value = false
	}
}
</script>

<style scoped lang="scss">
.form {
	max-width: unset;

	form {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 10px;

		& > * {
			width: 100%;
		}

		.form__footer-container {
			display: flex;
			align-items: center;
			gap: 10px;

			.form__change-password-button,
			.form__logout-button {
				width: 100%;
			}
		}

		&--edit {
			.form__footer-container {
				justify-content: space-between;
			}
		}
	}
}
</style>
