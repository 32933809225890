<template>
	<Header :title="headerTitle" />
	<Container class="complete-checkout">
		<Card
			v-if="complete"
			class="complete-checkout__card">
			<template #title>Thank you for you purchase!</template>
			<template #content>
				<p>We appreciate your business.</p>
				<p>
					A confirmation email will be sent to
					<strong id="customer-email">{{ userEmail }}</strong
					>.
				</p>
				<p>
					If you have any questions, please email
					<a
						class="complete-checkout__support-email"
						href="mailto:orders@codedjen.com"
						>support@codedjen.com</a
					>. We are happy to answer any questions and will reach out as soon as possible.
				</p>
				<hr />
				<p v-if="!detailsState.details.value?.repository_config">
					Please now configure your application's github repository information, so that we may
					deliver the source code to you as soon as possible.
				</p>
				<p v-if="detailsState.details.value?.repository_config">
					Your application is being delivered to your repository. You can view the status of the
					delivery on the application details page.
				</p>
				<div class="complete-checkout__footer">
					<Button
						v-if="detailsState.details.value?.repository_config"
						as="router-link"
						:to="`/apps/${route.params.id as string}`"
						icon="fal fa-mobile-alt"
						label="To application details" />
					<Button
						v-else
						as="router-link"
						:to="`/apps/${route.params.id as string}/repo-config`"
						icon="fab fa-github"
						label="Create Repository Configuration" />
				</div>
			</template>
		</Card>
	</Container>
</template>

<script setup lang="ts">
import Container from '@/components/Container.vue'
import Header from './components/Header.vue'
import StripeApi from '@/helpers/api/StripeApi'
import { computed, onBeforeMount, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import Card from 'primevue/card'
import Button from 'primevue/button'
import { useAppDetailsState } from '@/models/App/States'

const route = useRoute()
const router = useRouter()
const stripeApi = new StripeApi()
const complete = ref(false)
const userEmail = ref('')
const detailsState = useAppDetailsState()
detailsState.defaultParams = {
	with: ['repositoryConfig'],
}

const headerTitle = computed(() =>
	detailsState.details.value?.name ? detailsState.details.value?.name : 'Loading Details',
)

onBeforeMount(async () => {
	await getDetails()
})

onMounted(() => initialize())

async function initialize() {
	if (!route.query.session_id) {
		alert('Payment error, no session id provided. Please try again.')
		return router.push('/')
	}
	const res = await stripeApi.completeCheckout({ session_id: route.query.session_id as string })

	if (res.data.status === 'open') {
		return router.replace('/apps/' + route.params.id + '/checkout')
	} else if (res.data.status === 'complete') {
		complete.value = true
		userEmail.value = res.data.customer_email
	}
}

async function getDetails() {
	await detailsState.getDetails(route.params.id as string)
}
</script>

<style scoped lang="scss">
.complete-checkout {
	display: flex;
	align-items: center;
	justify-content: center;

	.complete-checkout__card {
		width: 600px;

		&:deep(.p-card-content) {
			display: flex;
			flex-direction: column;
			gap: 16px;

			.complete-checkout__support-email {
				color: var(--p-primary-500);
			}

			.complete-checkout__footer {
				display: flex;
				justify-content: flex-end;
			}
		}
	}
}
</style>
