<template>
	<div class="code-breakdown">
		<template v-if="schema.length <= 5">
			<div class="code-breakdown__title-container">
				<h1>Small Application</h1>
			</div>
		</template>
		<template v-if="schema.length > 5 && schema.length <= 15">
			<div class="code-breakdown__title-container">
				<h1>Medium Application</h1>
			</div>
		</template>
		<template v-if="schema.length > 15">
			<div class="code-breakdown__title-container">
				<h1>Large Application</h1>
			</div>
		</template>
		<h3>{{ schema.length - 1 }} Modules</h3>
		<h2>Database</h2>
		<table class="code-breakdown__table">
			<thead class="code-breakdown-thead">
				<tr>
					<th class="code-breakdown__th--left">Unit</th>
					<th>Amount</th>
					<th>Estimate</th>
					<th>Total estimate</th>
				</tr>
			</thead>
			<tbody class="code-breakdown-tbody">
				<tr>
					<td class="code-breakdown__td--left">Tables/Migrations</td>
					<td>{{ tablesCount }}</td>
					<td>15min</td>
					<td>{{ tablesEstimate }}h</td>
				</tr>
				<tr>
					<td
						class="code-breakdown__td--left"
						colspan="3">
						Total estimate
					</td>
					<td>{{ totalDatabaseEstimate }}h</td>
				</tr>
				<tr>
					<td
						class="code-breakdown__td--left"
						colspan="3">
						Laravel Developer Median Hourly Rate
					</td>
					<td>${{ LaravelDeveloperHourlyRate }}</td>
				</tr>
				<tr>
					<td
						class="code-breakdown__td--left"
						colspan="3">
						<strong>Total value</strong>
					</td>
					<td>
						<strong>${{ totalDatabaseValue }}</strong>
					</td>
				</tr>
			</tbody>
		</table>
		<h2>API</h2>
		<table class="code-breakdown__table">
			<thead class="code-breakdown-thead">
				<tr>
					<th class="code-breakdown__th--left">Unit</th>
					<th>Amount</th>
					<th>Estimate</th>
					<th>Total estimate</th>
				</tr>
			</thead>
			<tbody class="code-breakdown-tbody">
				<tr>
					<td class="code-breakdown__td--left">Models</td>
					<td>{{ modelsCount }}</td>
					<td>{{ MinutesPerModel }}min</td>
					<td>{{ modelsEstimate }}h</td>
				</tr>
				<tr>
					<td class="code-breakdown__td--left">Relations</td>
					<td>{{ relationsCount }}</td>
					<td>{{ MinutesPerRelation }}min</td>
					<td>{{ relationsEstimate }}h</td>
				</tr>
				<tr>
					<td class="code-breakdown__td--left">Controllers</td>
					<td>{{ controllersCount }}</td>
					<td>{{ MinutesPerController }}min</td>
					<td>{{ controllersEstimate }}h</td>
				</tr>
				<tr>
					<td class="code-breakdown__td--left">Routes</td>
					<td>{{ apiRoutesCount }}</td>
					<td>{{ MinutesPerApiRoute }}min</td>
					<td>{{ apiRoutesEstimate }}h</td>
				</tr>
				<tr>
					<td class="code-breakdown__td--left">Requests</td>
					<td>{{ requestsCount }}</td>
					<td>{{ MinutesPerRequest }}min</td>
					<td>{{ requestsEstimate }}h</td>
				</tr>
				<tr>
					<td class="code-breakdown__td--left">Factories</td>
					<td>{{ factoriesCount }}</td>
					<td>{{ MinutesPerFactory }}min</td>
					<td>{{ factoriesEstimate }}h</td>
				</tr>
				<tr>
					<td class="code-breakdown__td--left">Seeders</td>
					<td>{{ seedersCount }}</td>
					<td>{{ MinutesPerSeeder }}min</td>
					<td>{{ seedersEstimate }}h</td>
				</tr>
				<tr>
					<td
						class="code-breakdown__td--left"
						colspan="3">
						Total estimate
					</td>
					<td>{{ totalApiEstimate }}h</td>
				</tr>
				<tr>
					<td
						class="code-breakdown__td--left"
						colspan="3">
						Laravel Developer Median Hourly Rate
					</td>
					<td>${{ LaravelDeveloperHourlyRate }}</td>
				</tr>
				<tr>
					<td
						class="code-breakdown__td--left"
						colspan="3">
						<strong>Total value</strong>
					</td>
					<td>
						<strong>${{ totalApiValue }}</strong>
					</td>
				</tr>
			</tbody>
		</table>
		<h2>UI</h2>
		<table class="code-breakdown__table">
			<thead class="code-breakdown-thead">
				<tr>
					<th class="code-breakdown__th--left">Unit</th>
					<th>Amount</th>
					<th>Estimate</th>
					<th>Total estimate</th>
				</tr>
			</thead>
			<tbody class="code-breakdown-tbody">
				<tr>
					<td class="code-breakdown__td--left">List Views</td>
					<td>{{ listViewsCount }}</td>
					<td>{{ MinutesPerListView }}min</td>
					<td>{{ listViewsEstimate }}h</td>
				</tr>
				<tr>
					<td class="code-breakdown__td--left">Edit Views</td>
					<td>{{ editViewsCount }}</td>
					<td>{{ MinutesPerEditView }}min</td>
					<td>{{ editViewsEstimate }}h</td>
				</tr>
				<tr>
					<td class="code-breakdown__td--left">Forms</td>
					<td>{{ formsCount }}</td>
					<td>{{ MinutesPerForm }}min</td>
					<td>{{ formsEstimate }}h</td>
				</tr>
				<tr>
					<td class="code-breakdown__td--left">Relation Widgets</td>
					<td>{{ relationWidgetsCount }}</td>
					<td>{{ MinutesPerRelationWidget }}min</td>
					<td>{{ relationWidgetsEstimate }}h</td>
				</tr>
				<tr>
					<td class="code-breakdown__td--left">API Layers</td>
					<td>{{ apiLayersCount }}</td>
					<td>{{ MinutesPerApiLayer }}min</td>
					<td>{{ apiLayersEstimate }}h</td>
				</tr>
				<tr>
					<td class="code-breakdown__td--left">Routes</td>
					<td>{{ uiRoutesCount }}</td>
					<td>{{ MinutesPerUiRoute }}min</td>
					<td>{{ uiRoutesEstimate }}h</td>
				</tr>
				<tr>
					<td
						class="code-breakdown__td--left"
						colspan="3">
						Total estimate
					</td>
					<td>{{ totalUiEstimate }}h</td>
				</tr>
				<tr>
					<td
						class="code-breakdown__td--left"
						colspan="3">
						Vue Developer Median Hourly Rate
					</td>
					<td>${{ VueDeveloperHourlyRate }}</td>
				</tr>
				<tr>
					<td
						class="code-breakdown__td--left"
						colspan="3">
						<strong>Total value</strong>
					</td>
					<td>
						<strong>${{ totalUiValue }}</strong>
					</td>
				</tr>
			</tbody>
		</table>

		<p class="code-breakdown__emphasized">
			Assuming perfect time efficiency and no communication overhead and excluding
		</p>
		<ul>
			<li><i class="fas fa-check" /> Basic environment setup</li>
			<li><i class="fas fa-check" /> Linters configuration</li>
			<li><i class="fas fa-check" /> Formatters configuration</li>
			<li><i class="fas fa-check" /> CI/CD configuration</li>
			<li><i class="fas fa-check" /> Docker configuration</li>
			<li><i class="fas fa-check" /> Kubernetes configuration</li>
			<li><i class="fas fa-check" /> Testing environments configuration</li>
			<li><i class="fas fa-check" /> Environment variables and config configuration</li>
			<li><i class="fas fa-check" /> Authentication configuration</li>
			<li><i class="fas fa-check" /> Readme and documentation</li>
		</ul>

		<hr />

		<h3 class="code-breakdown__totals">
			Total development time:
			<strong>{{ totalDatabaseEstimate + totalApiEstimate + totalUiEstimate }}h</strong>
		</h3>
		<h3 class="code-breakdown__totals">
			Total development value:
			<strong>${{ totalDatabaseValue + totalApiValue + totalUiValue }}</strong>
		</h3>
	</div>
</template>

<script setup lang="ts">
import type { EnrichedModuleSchema } from '@/interfaces/schemas/enrichedModuleSchema'
import { computed } from 'vue'

const props = defineProps<{
	schema: EnrichedModuleSchema[]
}>()

const LaravelDeveloperHourlyRate = 44
const VueDeveloperHourlyRate = 54

const totalDatabaseEstimate = computed(() => {
	return tablesEstimate.value
})
const totalDatabaseValue = computed(() => {
	return Number((totalDatabaseEstimate.value * LaravelDeveloperHourlyRate).toFixed(2))
})

const totalApiEstimate = computed(() => {
	return (
		modelsEstimate.value +
		relationsEstimate.value +
		controllersEstimate.value +
		apiRoutesEstimate.value +
		requestsEstimate.value +
		factoriesEstimate.value +
		seedersEstimate.value
	)
})
const totalApiValue = computed(() => {
	return Number((totalApiEstimate.value * LaravelDeveloperHourlyRate).toFixed(2))
})

const totalUiEstimate = computed(() => {
	return (
		listViewsEstimate.value +
		editViewsEstimate.value +
		formsEstimate.value +
		relationWidgetsEstimate.value +
		apiLayersEstimate.value +
		uiRoutesEstimate.value
	)
})
const totalUiValue = computed(() => {
	return Number((totalUiEstimate.value * VueDeveloperHourlyRate).toFixed(2))
})

const MinutesPerTable = 15
const tablesCount = computed(() => {
	let count = 0
	count += props.schema.length
	for (const module of props.schema) {
		for (const relationName in module.model.relations) {
			const relation = module.model.relations[relationName]
			if (
				relation.relationType === 'BelongsToMany' &&
				relation.name.localeCompare(relation.inverse) < 0
			) {
				count += 1
			}
		}
	}
	return count
})
const tablesEstimate = computed(() => {
	return (tablesCount.value * MinutesPerTable) / 60
})

const MinutesPerRelationWidget = 30
const relationWidgetsCount = computed(() => {
	let count = 0
	for (const module of props.schema) {
		for (const relationName in module.model.relations) {
			const relation = module.model.relations[relationName]
			if (
				relation.relationType === 'BelongsToMany' ||
				relation.relationType === 'HasMany' ||
				relation.relationType === 'HasOne'
			) {
				count += 1
			}
		}
	}
	return count
})
const relationWidgetsEstimate = computed(() => {
	return (relationWidgetsCount.value * MinutesPerRelationWidget) / 60
})

const MinutesPerRelation = 5
const relationsCount = computed(() => {
	let count = 0
	for (const module of props.schema) {
		count += Object.keys(module.model.relations).length
	}
	return count
})
const relationsEstimate = computed(() => {
	return Number(((relationsCount.value * MinutesPerRelation) / 60).toFixed(2))
})

const MinutesPerModel = 5
const modelsCount = computed(() => {
	return props.schema.length - 1
})
const modelsEstimate = computed(() => {
	return Number(((modelsCount.value * MinutesPerModel) / 60).toFixed(2))
})

const MinutesPerController = 15
const controllersCount = computed(() => {
	return props.schema.length - 1
})
const controllersEstimate = computed(() => {
	return Number(((controllersCount.value * MinutesPerController) / 60).toFixed(2))
})

const MinutesPerApiRoute = 5
const apiRoutesCount = computed(() => {
	return (props.schema.length - 1) * 3
})
const apiRoutesEstimate = computed(() => {
	return Number(((apiRoutesCount.value * 3 * MinutesPerApiRoute) / 60).toFixed(2))
})

const MinutesPerRequest = 10
const requestsCount = computed(() => {
	return (props.schema.length - 1) * 2
})
const requestsEstimate = computed(() => {
	return Number(((requestsCount.value * 2 * MinutesPerRequest) / 60).toFixed(2))
})

const MinutesPerFactory = 5
const factoriesCount = computed(() => {
	return props.schema.length - 1
})
const factoriesEstimate = computed(() => {
	return Number(((factoriesCount.value * MinutesPerFactory) / 60).toFixed(2))
})

const MinutesPerSeeder = 5
const seedersCount = computed(() => {
	return props.schema.length - 1
})
const seedersEstimate = computed(() => {
	return Number(((seedersCount.value * MinutesPerSeeder) / 60).toFixed(2))
})

const MinutesPerListView = 15
const listViewsCount = computed(() => {
	return props.schema.length - 1
})
const listViewsEstimate = computed(() => {
	return Number(((listViewsCount.value * MinutesPerListView) / 60).toFixed(2))
})

const MinutesPerEditView = 15
const editViewsCount = computed(() => {
	return props.schema.length - 1
})
const editViewsEstimate = computed(() => {
	return Number(((editViewsCount.value * MinutesPerEditView) / 60).toFixed(2))
})

const MinutesPerForm = 15
const formsCount = computed(() => {
	return props.schema.length - 1
})
const formsEstimate = computed(() => {
	return Number(((formsCount.value * MinutesPerForm) / 60).toFixed(2))
})

const MinutesPerApiLayer = 15
const apiLayersCount = computed(() => {
	return props.schema.length - 1
})
const apiLayersEstimate = computed(() => {
	return Number(((apiLayersCount.value * MinutesPerApiLayer) / 60).toFixed(2))
})

const MinutesPerUiRoute = 5
const uiRoutesCount = computed(() => {
	return props.schema.length - 1
})
const uiRoutesEstimate = computed(() => {
	return Number(((uiRoutesCount.value * MinutesPerUiRoute) / 60).toFixed(2))
})
</script>

<style scoped lang="scss">
.code-breakdown {
	padding-bottom: 50px;
	.code-breakdown__title-container {
		display: flex;
		align-items: flex-end;
		gap: 10px;
		line-height: 1;
	}

	h1 {
		font-size: 28px;
		line-height: 1;
	}

	h2 {
		font-size: 24px;
		margin-top: 30px;
		margin-bottom: 10px;
		line-height: 1;
	}

	h3 {
		font-size: 20px;
		margin-top: 10px;
		line-height: 1;
	}

	.code-breakdown__table {
		width: 100%;
		border-collapse: collapse;
		margin-bottom: 20px;

		&,
		td,
		th {
			border: 1px solid #ddd;
		}

		.code-breakdown-thead {
			background-color: #f5f5f5;
		}

		.code-breakdown__th {
			padding: 10px;
			border: 1px solid #ddd;
			text-align: left;
		}

		td {
			padding: 10px;
			text-align: right;
		}

		.code-breakdown__td--left {
			text-align: left;
		}

		th {
			padding: 10px;
			text-align: right;
		}

		.code-breakdown__th--left {
			text-align: left;
		}
	}

	.code-breakdown__emphasized {
		font-size: 22px;
	}

	.code-breakdown__totals {
		width: 100%;
		display: flex;
		justify-content: space-between;
		font-weight: bold;
	}

	hr {
		margin: 20px 0;
	}

	ul {
		list-style-position: inside;

		li {
			font-size: 18px;

			i {
				color: var(--p-green-500);
				margin-right: 5px;
			}
		}
	}
}
</style>
