import { useAuthStore } from '@/stores/Auth'
import type { RouteLocationNormalizedLoaded } from 'vue-router'

export default async (to: RouteLocationNormalizedLoaded) => {
	const auth = useAuthStore()

	// Save the last route before redirecting to login
	if (
		to.path !== '/login' &&
		to.path !== '/register' &&
		to.path !== '/verify-email' &&
		to.path !== '/forgot-password' &&
		to.path !== '/reset-password' &&
		to.path !== '/logout'
	) {
		auth.setLastRoute(to.fullPath)
	}

	// Get the user if it's not already loaded
	if (!auth.user) {
		await auth.getUser()
	}

	if (auth.user) {
		// Redirect to the last route if the user is logged in
		if (
			to.path === '/login' ||
			to.path === '/register' ||
			to.path === '/verify-email' ||
			to.path === '/forgot-password'
		) {
			return auth.lastRoute ?? '/'
		}

		// Redirect to verify email if the user is not verified
		if (!auth.user.verified && to.path !== '/verify-email') {
			return '/verify-email'
		}

		// Redirect to the last route if the user is verified
		if (auth.user.verified && to.path === '/verify-email') {
			return auth.lastRoute ?? '/'
		}
	} else if (
		to.path === '/login' ||
		to.path === '/register' ||
		to.path === '/verify-email' ||
		to.path === '/forgot-password' ||
		to.path === '/reset-password'
	) {
		// Continue to unprotected routes if user is not logged in
		return true
	} else {
		// Redirect to login if the user is not logged in and on protected route
		return '/login'
	}
}
